import moment from 'moment';

export function firstImageProduct (images) {
  if (images && images[0] && images[0].path) {
    return process.env.VUE_APP_FILE_URL + '/' + images[0].path.replace('public/', '');
  }
  return '';
}

export function formatUrlImage (image) {
  if (image) {
    return process.env.VUE_APP_FILE_URL + '/' + image.replace('public/', '');
  }
  return '';
}

export function date (date, format = 'YYYY/MM/DD') {
  if (date) {
    return moment(date).format(format);
  }
  return '';
}

export function datetime (datetime, formatDatetime = 'YYYY/MM/DD HH:mm') {
  if (datetime) {
    return moment(datetime).format(formatDatetime);
  }
  return '';
}
