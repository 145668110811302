export default {
  breadcrumbs: {
    list: 'メンテナンス一覧',
    register: 'メンテナンス登録',
    edit: 'メンテナンス変更',
  },
  label: {
    status: 'メンテナンス状況',
    start_time: '開始時間',
    end_time: '終了時間',
    message: 'メッセージ',
    scope: '影響範囲',
    date_search: '日付検索',
    maintain_status: 'メンテナンス状況',
  },
  scopes: {
    all: 'すべて',
    system: '指定システム',
    client: '指定クライアント',
    company: '指定提携企業',
  },
  button: {
    all: 'すべて',
    system_select: 'システムを選択',
    client_select: 'クライアントを選択',
    company_select: '提携企業を選択',
    store_select: '提携店を選択',
    register: 'メンテナンス登録',
  },
  message: {
    selected_system_quantity: '選ばれた{quantity}のシステム',
    selected_client_quantity: '選ばれた{quantity}のクライアント',
    selected_company_quantity: '選ばれた{quantity}の提携企業',
    selected_store_quantity: '選ばれた{quantity}の提携店',
    scope_required: '影響範囲を入力してください。',
  },
  table: {
    system_id: 'システム ID',
    system_name: 'システム名',
    system_code: 'システムコード',
    client_code: 'クライアントコード',
    client_name: 'クライアント名',
    company_name: '提携企業名',
    store_name: '提携店名',
    start_date: '開始日',

    maintain_type: 'メンテナンス状況',
    start_time: '開始時間',
    end_time: '終了時間',
    created_by: '作成ユーザー',
    scope: '影響範囲',
    action: 'アクション',
  },
  dialog: {
    delete: {
      message_confirm: 'こちらのメンテナンスを削除してもよろしいですか。',
      message_success: '削除が完了しました。',
    },
  },
  status: {
    on: 'ON',
    off: 'OFF',
  },
};
