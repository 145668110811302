const permission = {
  gs_admin: {
    system: {
      routes: ['list_system', 'create_system', 'edit_system'],
      functions: ['delete'],
    },
    client: {
      routes: ['list_client', 'create_client', 'edit_client'],
      functions: ['delete', 'csv'],
    },
    company: {
      routes: ['list_company', 'create_company', 'edit_company'],
      functions: ['delete', 'csv'],
    },
    store: {
      routes: ['list_store', 'create_store', 'edit_store'],
      functions: ['delete', 'csv_import', 'csv_export', 'service_point_import'],
    },
    user: {
      routes: ['list_user', 'create_user', 'edit_user'],
      functions: ['delete', 'csv_import', 'csv_export', 'full_update'],
    },
    promotion: {
      routes: ['list_promotion', 'create_promotion', 'edit_promotion', 'calendar'],
      functions: ['delete'],
    },
    transaction: {
      routes: ['list_transaction', 'edit_add_transaction', 'edit_use_transaction', 'detail_add_transaction', 'detail_use_transaction'],
      functions: ['csv_export_cs21', 'csv_export'],
    },
    report: {
      routes: ['report_daily_performance', 'report_monthly_performance'],
      functions: ['csv_output'],
    },
    batch_log: {
      routes: ['log_batch_sent', 'log_batch_received'],
      functions: ['csv_output'],
    },
    operation_log: {
      routes: ['user_operations'],
      functions: [],
    },
    invoice: {
      routes: ['list_invoice'],
      functions: ['download', 'upload', 'delete'],
    },
    tax: {
      routes: ['tax_setting'],
      functions: ['delete'],
    },
    maintenance: {
      routes: ['list_maintenance', 'create_maintain', 'edit_maintain'],
      functions: ['delete'],
    },
    manual_import: {
      routes: ['list_manual_import'],
      functions: [],
    },
    information: {
      routes: ['list_information', 'create_information', 'update_information', 'upload_information_attachment', 'detail_information'],
      functions: ['delete', 'full_update'],
    },
  },
  client_manager: {
    system: {
      routes: [],
      functions: [],
    },
    client: {
      routes: [],
      functions: [],
    },
    company: {
      routes: [],
      functions: [],
    },
    store: {
      routes: ['list_store'],
      functions: [],
    },
    user: {
      routes: ['list_user', 'edit_user'],
      functions: ['csv_export'],
    },
    promotion: {
      routes: ['list_promotion', 'create_promotion', 'edit_promotion', 'calendar'],
      functions: ['delete'],
    },
    transaction: {
      routes: ['list_transaction', 'edit_add_transaction', 'edit_use_transaction', 'detail_add_transaction', 'detail_use_transaction'],
      functions: ['csv_export_cs21', 'csv_export'],
    },
    report: {
      routes: ['report_daily_performance', 'report_monthly_performance'],
      functions: ['csv_output'],
    },
    batch_log: {
      routes: [],
      functions: [],
    },
    operation_log: {
      routes: [],
      functions: [],
    },
    invoice: {
      routes: ['list_invoice'],
      functions: ['download'],
    },
    tax: {
      routes: [],
      functions: [],
    },
    maintenance: {
      routes: [],
      functions: [],
    },
    manual_import: {
      routes: [],
      functions: [],
    },
    information: {
      routes: ['list_information', 'create_information', 'update_information', 'upload_information_attachment', 'detail_information'],
      functions: ['delete', 'full_update'],
    },
  },
  company_manager: {
    system: {
      routes: [],
      functions: [],
    },
    client: {
      routes: [],
      functions: [],
    },
    company: {
      routes: [],
      functions: [],
    },
    store: {
      routes: [],
      functions: [],
    },
    user: {
      routes: ['list_user', 'edit_user'],
      functions: ['csv_export'],
    },
    promotion: {
      routes: ['list_promotion', 'create_promotion', 'edit_promotion', 'calendar'],
      functions: ['delete'],
    },
    transaction: {
      routes: ['list_transaction', 'edit_add_transaction', 'edit_use_transaction', 'detail_add_transaction', 'detail_use_transaction'],
      functions: [],
    },
    report: {
      routes: ['report_daily_performance', 'report_monthly_performance'],
      functions: ['csv_output'],
    },
    batch_log: {
      routes: [],
      functions: [],
    },
    operation_log: {
      routes: [],
      functions: [],
    },
    invoice: {
      routes: ['list_invoice'],
      functions: ['download'],
    },
    tax: {
      routes: [],
      functions: [],
    },
    maintenance: {
      routes: [],
      functions: [],
    },
    manual_import: {
      routes: [],
      functions: [],
    },
    information: {
      routes: ['list_information', 'detail_information'],
      functions: [],
    },
  },
  store_manager: {
    system: {
      routes: [],
      functions: [],
    },
    client: {
      routes: [],
      functions: [],
    },
    company: {
      routes: [],
      functions: [],
    },
    store: {
      routes: [],
      functions: [],
    },
    user: {
      routes: ['list_user', 'edit_user'],
      functions: [],
    },
    promotion: {
      routes: ['list_promotion', 'create_promotion', 'edit_promotion', 'calendar'],
      functions: ['delete'],
    },
    transaction: {
      routes: ['list_transaction', 'edit_add_transaction', 'edit_use_transaction', 'detail_add_transaction', 'detail_use_transaction'],
      functions: [],
    },
    report: {
      routes: ['report_daily_performance', 'report_monthly_performance'],
      functions: ['csv_output'],
    },
    batch_log: {
      routes: [],
      functions: [],
    },
    operation_log: {
      routes: [],
      functions: [],
    },
    invoice: {
      routes: ['list_invoice'],
      functions: ['download'],
    },
    tax: {
      routes: [],
      functions: [],
    },
    maintenance: {
      routes: [],
      functions: [],
    },
    manual_import: {
      routes: [],
      functions: [],
    },
    information: {
      routes: ['list_information', 'detail_information'],
      functions: [],
    },
  },
};

export default permission;
