import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import AuthorizationService from '@/services/authorizationService';

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
  {
    path: '/login',
    name: 'login',
    component: () =>
      import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: { layout: 'auth', requiresAuth: false },
  },
  {
    path: '/',
    name: 'home',
    component: () =>
      import(/* webpackChunkName: "home" */ '../views/HomePage.vue'),
    meta: { requiresAuth: true },
  },
  {
    path: '/systems',
    name: 'list_system',
    component: () =>
      import(/* webpackChunkName: "system" */ '../views/system/ListSystem.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'system' },
  },
  {
    path: '/systems/create',
    name: 'create_system',
    component: () =>
      import(/* webpackChunkName: "system" */ '../views/system/InputSystem.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'system' },
  },
  {
    path: '/systems/:system_id',
    name: 'edit_system',
    component: () =>
      import(/* webpackChunkName: "system" */ '../views/system/InputSystem.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'system' },
  },

  {
    path: '/clients',
    name: 'list_client',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/client/ListClient.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'client' },
  },
  {
    path: '/clients/create',
    name: 'create_client',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/client/InputClient.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'client' },
  },
  {
    path: '/clients/:client_id',
    name: 'edit_client',
    component: () =>
      import(/* webpackChunkName: "client" */ '../views/client/InputClient.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'client' },
  },

  {
    path: '/partner-companies',
    name: 'list_company',
    component: () =>
      import(/* webpackChunkName: "company" */ '../views/company/ListCompany.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'company' },
  },
  {
    path: '/partner-companies/create',
    name: 'create_company',
    component: () =>
      import(/* webpackChunkName: "company" */ '../views/company/InputCompany.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'company' },
  },
  {
    path: '/partner-companies/:company_id',
    name: 'edit_company',
    component: () =>
      import(/* webpackChunkName: "company" */ '../views/company/InputCompany.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'company' },
  },

  {
    path: '/partner-stores',
    name: 'list_store',
    component: () =>
      import(/* webpackChunkName: "store" */ '../views/store/ListStore.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'store' },
  },
  {
    path: '/partner-stores/create',
    name: 'create_store',
    component: () =>
      import(/* webpackChunkName: "store" */ '../views/store/InputStore.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'store' },
  },
  {
    path: '/partner-stores/:store_id',
    name: 'edit_store',
    component: () =>
      import(/* webpackChunkName: "store" */ '../views/store/InputStore.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'store' },
  },

  {
    path: '/users',
    name: 'list_user',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/user/ListUser.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'user' },
  },
  {
    path: '/users/create',
    name: 'create_user',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/user/InputUser.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'user' },
  },
  {
    path: '/users/:user_id',
    name: 'edit_user',
    component: () =>
      import(/* webpackChunkName: "user" */ '../views/user/InputUser.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'user' },
  },

  {
    path: '/transactions',
    name: 'list_transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/ListTransaction.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'transaction' },
  },

  {
    path: '/transactions/add-point/:transaction_id',
    name: 'detail_add_transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/DetailTransactionAddPoint.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'transaction' },
  },
  {
    path: '/transactions/add-point/:transaction_id/edit',
    name: 'edit_add_transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/InputTransactionAddPoint.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'transaction' },
  },
  {
    path: '/transactions/use-point/:transaction_id',
    name: 'detail_use_transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/DetailTransactionUsePoint.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'transaction' },
  },
  {
    path: '/transactions/use-point/:transaction_id/edit',
    name: 'edit_use_transaction',
    component: () =>
      import(/* webpackChunkName: "transaction" */ '../views/transaction/InputTransactionUsePoint.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'transaction' },
  },
  {
    path: '/promotions',
    name: 'list_promotion',
    component: () =>
      import(/* webpackChunkName: "promotion" */ '../views/promotion/ListPromotion.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'promotion' },
  },
  {
    path: '/promotions/create',
    name: 'create_promotion',
    component: () =>
      import(/* webpackChunkName: "promotion" */ '../views/promotion/Create.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'promotion' },
  },
  {
    path: '/promotions/:promotion_id',
    name: 'edit_promotion',
    component: () =>
      import(/* webpackChunkName: "promotion" */ '../views/promotion/Edit.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'promotion' },
  },
  {
    path: '/promotion/calendar',
    name: 'calendar',
    component: () =>
      import(/* webpackChunkName: "promotion" */ '../views/promotion/Calendar.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'promotion' },
  },

  {
    path: '/reports/daily-performance',
    name: 'report_daily_performance',
    component: () =>
      import(/* webpackChunkName: "report" */ '../views/report/ReportDailyPerformance.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'report' },
  },
  {
    path: '/reports/monthly-performance',
    name: 'report_monthly_performance',
    component: () =>
      import(/* webpackChunkName: "report" */ '../views/report/ReportMonthlyPerformance.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'report' },
  },
  {
    path: '/logs/sent',
    name: 'log_batch_sent',
    component: () =>
      import(/* webpackChunkName: "batch_log" */ '../views/log/ListSentBatchLog.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'batch_log' },
  },
  {
    path: '/logs/received',
    name: 'log_batch_received',
    component: () =>
      import(/* webpackChunkName: "batch_log" */ '../views/log/ListReceivedBatchLog.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'batch_log' },
  },
  {
    path: '/logs/user-operations',
    name: 'user_operations',
    component: () =>
      import(/* webpackChunkName: "log_operation" */ '../views/log/ListUserOperationLog.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'operation_log' },
  },
  {
    path: '/invoices',
    name: 'list_invoice',
    component: () =>
      import(/* webpackChunkName: "invoice" */ '../views/invoice/ListInvoice.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'invoice' },
  },
  {
    path: '/tax-setting',
    name: 'tax_setting',
    component: () =>
      import(/* webpackChunkName: "tax" */ '../views/tax/TaxSetting.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'tax' },
  },
  {
    path: '/maintenances',
    name: 'list_maintenance',
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '../views/maintain/ListMaintain.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'maintenance' },
  },
  {
    path: '/maintenances/create',
    name: 'create_maintain',
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '../views/maintain/InputMaintain.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'maintenance' },
  },
  {
    path: '/maintenances/edit/:maintenance_id',
    name: 'edit_maintain',
    component: () =>
      import(/* webpackChunkName: "maintenance" */ '../views/maintain/InputMaintain.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'maintenance' },
  },
  {
    path: '/manual-import',
    name: 'list_manual_import',
    component: () =>
      import(/* webpackChunkName: "manual_import" */ '../views/manual_import/ListManualImport.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'manual_import' },
  },

  {
    path: '/informations',
    name: 'list_information',
    component: () =>
      import(/* webpackChunkName: "information" */ '../views/information/ListInformation.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'information' },
  },
  {
    path: '/informations/create',
    name: 'create_information',
    component: () =>
      import(/* webpackChunkName: "information" */ '../views/information/InputInformation.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'information' },
  },
  {
    path: '/informations/:information_id/edit',
    name: 'update_information',
    component: () =>
      import(/* webpackChunkName: "information" */ '../views/information/InputInformation.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'information' },
  },
  {
    path: '/informations/:information_id',
    name: 'detail_information',
    component: () =>
      import(/* webpackChunkName: "information" */ '../views/information/DetailInformation.vue'),
    meta: { requiresAuth: true, requiredAuthorization: true, routeGroup: 'information' },
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.state.userStore.token;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (isAuthenticated) {
      if (isRequiredAuthorization(to)) {
        const authorizationService = new AuthorizationService();
        const isHasPermission = authorizationService.hasPermission('routes', to.name, to.meta.routeGroup);
        if (!isHasPermission) return next({ name: 'home' });
      }

      return next();
    }

    return next('/login');
  }
  if (to.name === 'login' && isAuthenticated) {
    return next('/');
  }
  next();
});

function isRequiredAuthorization (nextRoute) {
  return nextRoute.meta && nextRoute.meta.requiredAuthorization;
}

router.onError(error => {
  if (/loading chunk \d* failed./i.test(error.message) && navigator.onLine) {
    window.location.reload();
  }
});

export default router;
