<template>
  <div class="admin-pagination" :class="{'hide-next-prev': disableNextPrev}">
      <div
        :disabled="isFirstDisabled"
        class="v-pagination__navigation v-pagination__navigation--first"
        @click="currentPage = 1"
        v-if="!disableNextPrev"
      >
        <v-icon
          large
          color="#666"
        >
          mdi-chevron-double-left
        </v-icon>
      </div>
      <v-pagination
        v-model="currentPage"
        :value="value"
        :length="length"
        :totalVisible="totalVisible"
      ></v-pagination>
      <div
        :disabled="isLastDisabled"
        v-if="!disableNextPrev"
        class="v-pagination__navigation v-pagination__navigation--last"
        @click="currentPage = length"
      >
        <v-icon
          large
          color="#666"
        >
          mdi-chevron-double-right
        </v-icon>
      </div>
  </div>
</template>

<script>
const DEFAULT_TOTAL_VISIBLE = 7;

export default {
  name: 'AdminPagination',
  props: {
    value: { type: Number, default: 0 },
    disabled: { type: Boolean, default: false },
    length: { type: Number, default: 0 },
    totalVisible: { type: Number, default: DEFAULT_TOTAL_VISIBLE },
  },
  created () {
    this.currentPage = this.value;
  },
  data () {
    return {
      currentPage: 0,
    };
  },
  computed: {
    isFirstDisabled () {
      return this.currentPage <= 1;
    },
    isLastDisabled () {
      return this.currentPage >= this.length;
    },
    disableNextPrev () {
      return this.length <= 1;
    },
  },
  watch: {
    currentPage: {
      handler (newVal) {
        this.$emit('input', this.currentPage);
      },
    },
    value: {
      handler (newVal) {
        this.currentPage = newVal;
      },
    },
  },
};
</script>
<style lang="scss">

</style>
