import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();
const actions = {
  async getCountNotYetApprove (_, payload) {
    let count = 0;
    const response = await http.get(apiEndpoints.count_not_yet_approve);
    if (response.status) {
      count = response.data.result.count;
    }
    return count;
  },
};
export default {
  state,
  actions,
};
