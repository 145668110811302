<template>
  <nav id="sidebar" class="sidebar" :class="{ collapsed: !isShowSidebar }">
    <div class="sidebar-content js-simplebar">
      <v-list class="mt-xl-14">
        <v-list-item
          v-for="(item, index) in sidebarMenuItems"
          :key="index"
          :class="{
            'sidebar-item': item.type === MENU_CHILD,
            active:
              ($route.path.includes(item.path) && item.path) ||
              ($route.name === 'home' && $route.name === item.router_name),
          }"
        >
          <v-list-group
            v-if="item.type === MENU_PARENT"
            :value="false"
            class="sidebar-dropdown"
          >
            <template v-slot:activator>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </template>

            <v-list-item
              v-for="(child, childIndex) in item.children"
              :key="childIndex"
            >
              <router-link
                class="sidebar-link sidebar-link--sub"
                :to="{ name: child.router_name, params: { ...child.params } }"
                :class="{
                  active:
                    ($route.path.includes(item.path) && item.path) ||
                    ($route.name === 'home' &&
                      $route.name === item.router_name),
                }"
              >
                <span class="align-middle">{{ child.name }}</span>
              </router-link>
            </v-list-item>
          </v-list-group>
          <router-link
            v-else
            class="sidebar-link"
            :to="{ name: item.router_name }"
            :class="{
              'sidebar-item': item.type === MENU_CHILD,
              active:
                ($route.path.includes(item.path) && item.path) ||
                ($route.name === 'home' && $route.name === item.router_name),
            }"
          >
            <span class="align-middle" v-html="item.name"></span>
          </router-link>
        </v-list-item>
      </v-list>
    </div>
  </nav>
</template>

<script>
import AuthorizationService from '@/services/authorizationService';
import { mapActions, mapGetters } from 'vuex';

const MENU_PARENT = 1;
const MENU_CHILD = 2;

export default {
  name: 'AdminSidebar',
  props: ['isShowSidebar'],
  data () {
    return {
      admins: [
        ['Management', 'mdi-account-multiple-outline'],
        ['Settings', 'mdi-cog-outline'],
      ],
      cruds: [
        ['Create', 'mdi-plus-outline'],
        ['Read', 'mdi-file-outline'],
        ['Update', 'mdi-update'],
        ['Delete', 'mdi-delete'],
      ],
      status: true,
      MENU_CHILD: MENU_CHILD,
      MENU_PARENT: MENU_PARENT,
      count_infor_not_yet_approve: null,
    };
  },
  async created () {
    if (this.isGSAdmin || this.isClientManager) {
      this.count_infor_not_yet_approve = await this.getCountNotYetApprove();
    }
  },
  computed: {
    ...mapGetters({
      isGSAdmin: 'userStore/isGSAdmin',
      isClientManager: 'userStore/isClientManager',
    }),
    sidebarMenuItems () {
      let informationLabel = this.$t('common.sidebar.list_information');
      if (this.count_infor_not_yet_approve !== null) {
        informationLabel += ` <span class="text-danger">(${this.count_infor_not_yet_approve})</span>`;
      }
      const fullMenu = [
        {
          type: MENU_CHILD,
          icon: 'mdi-view-dashboard-outline',
          router_name: 'home',
          path: '',
          name: this.$t('common.sidebar.home'),
          requiredAuthorization: false,
        },
        {
          type: MENU_CHILD,
          icon: 'mdi-view-dashboard-outline',
          router_name: 'list_information',
          path: '/informations',
          name: informationLabel,
          requiredAuthorization: true,
        },
        {
          type: MENU_CHILD,
          router_name: 'list_system',
          path: '/systems',
          name: this.$t('common.sidebar.list_system'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_client',
          path: '/clients',
          name: this.$t('common.sidebar.list_client'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_company',
          path: '/partner-companies',
          name: this.$t('common.sidebar.list_company'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_store',
          path: '/partner-stores',
          name: this.$t('common.sidebar.list_store'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_user',
          path: '/users',
          name: this.$t('common.sidebar.list_user'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_promotion',
          path: '/promotions',
          name: this.$t('common.sidebar.list_promotion'),
        },
        {
          type: MENU_CHILD,
          router_name: 'calendar',
          path: '/promotion/calendar',
          name: this.$t('common.sidebar.calendar'),
        },

        {
          type: MENU_CHILD,
          router_name: 'list_transaction',
          path: '/transactions',
          name: this.$t('common.sidebar.list_transaction'),
        },
        {
          type: MENU_PARENT,
          router_name: 'report',
          path: '/reports',
          name: this.$t('common.sidebar.report'),
          children: [
            {
              router_name: 'report_daily_performance',
              path: '/reports/daily-performance',
              name: this.$t('common.sidebar.daily_performance'),
            },
            {
              router_name: 'report_monthly_performance',
              path: '/reports/monthly-performance',
              name: this.$t('common.sidebar.monthly_performance'),
            },
          ],
          requiredAuthorization: false,
        },
        {
          type: MENU_CHILD,
          router_name: 'list_invoice',
          path: '/invoices',
          name: this.$t('common.sidebar.list_invoice'),
        },
        {
          type: MENU_CHILD,
          router_name: 'log_batch_sent',
          path: '/logs/sent',
          name: this.$t('common.sidebar.log_sent_batch'),
        },
        {
          type: MENU_CHILD,
          router_name: 'log_batch_received',
          path: '/logs/received',
          name: this.$t('common.sidebar.log_received_batch'),
        },

        {
          type: MENU_CHILD,
          router_name: 'user_operations',
          path: '/logs/user-operations',
          name: this.$t('common.sidebar.log_user_operation'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_maintenance',
          path: '/maintenances',
          name: this.$t('common.sidebar.maintain_setting'),
        },
        {
          type: MENU_CHILD,
          router_name: 'tax_setting',
          path: '/tax-setting',
          name: this.$t('common.sidebar.tax_setting'),
        },
        {
          type: MENU_CHILD,
          router_name: 'list_manual_import',
          path: '/manual-import',
          name: this.$t('common.sidebar.manual_import'),
        },
      ];
      const authorizationService = new AuthorizationService();
      const accessibleRoutes = authorizationService.getAllAccessibleRoutesByUser();

      const filteredMenu = fullMenu.filter(x => x.requiredAuthorization === false || accessibleRoutes.indexOf(x.router_name) !== -1);
      filteredMenu.forEach(item => {
        if (item.type === MENU_PARENT) {
          item.children = item.children.filter(child => !child.requiredAuthorization || accessibleRoutes.indexOf(child.router_name) !== -1);
        }
      });

      return filteredMenu;
    },
  },
  methods: {
    ...mapActions({
      getCountNotYetApprove: 'informationStore/getCountNotYetApprove',
    }),
  },
};
</script>
<style>
.v-list-group__items .v-list-item .sidebar-link.sidebar-link--sub.router-link-exact-active {
  background: #00996C;
  border-left-color: #3B7DDD;
}

.text-danger {
  color: red !important;
  font-weight: bold;
}
</style>
