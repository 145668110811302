<template>
  <v-dialog v-model="display" persistent :width="dialogWidth">
    <template v-slot:activator="{ on }">
      <v-text-field
        :disabled="disabled"
        :loading="loading"
        :label="label"
        :value="selectedDatetime | datetime"
        hide-details
        readonly
        clearable
        outlined
        dense
        autocomplete="off"
        class="v-input--cursor"
        v-on="on"
        @click:clear="clearHandler"
      >
        <template slot="append">
          <svg-icon class="cursor-pointer" icon="calendar" @click="openDialog"></svg-icon>
        </template>
      </v-text-field>
    </template>

    <v-card>
      <v-card-text class="px-0 py-0">
        <v-tabs fixed-tabs v-model="activeTab">
          <v-tab key="calendar">
            <slot name="dateIcon">
              <svg-icon icon="calendar"></svg-icon>
            </slot>
          </v-tab>
          <v-tab key="timer" :disabled="dateSelected">
            <slot name="timeIcon">
              <v-icon>mdi-clock-outline</v-icon>
            </slot>
          </v-tab>
          <v-tab-item key="calendar">
            <v-date-picker
              v-model="date"
              no-title
              locale="ja-jp"
              color="#4CAF50"
              @input="showTimePicker"
              :day-format="(date) => new Date(date).getDate()"
              full-width
            ></v-date-picker>
          </v-tab-item>
          <v-tab-item key="timer">
            <v-time-picker
              ref="timer"
              class="v-time-picker-custom"
              locale="ja-jp"
              color="#4CAF50"
              format="24hr"
              v-model="time"
              full-width
            ></v-time-picker>
          </v-tab-item>
        </v-tabs>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <slot name="actions" :parent="this">
          <v-btn text @click.native="cancelHandler">{{
            $t('common.button.cancel')
          }}</v-btn>
          <v-btn text color="green" @click="okHandler">{{
            $t('common.button.ok')
          }}</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
const DEFAULT_DATE = '';
const DEFAULT_TIME = '00:00';
const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
const DEFAULT_TIME_FORMAT = 'HH:mm';
const DEFAULT_DIALOG_WIDTH = 340;
const TAB_PICK_DATE = 0;
const TAB_PICK_TIME = 1;

export default {
  name: 'DateTimePicker',
  model: {
    prop: 'datetime',
    event: 'input',
  },
  props: {
    datetime: {
      type: [Date, String],
      default: null,
    },
    disabled: {
      type: Boolean,
    },
    loading: {
      type: Boolean,
    },
    label: {
      type: String,
      default: '',
    },
    dialogWidth: {
      type: Number,
      default: DEFAULT_DIALOG_WIDTH,
    },
    dateFormat: {
      type: String,
      default: DEFAULT_DATE_FORMAT,
    },
    timeFormat: {
      type: String,
      default: DEFAULT_TIME_FORMAT,
    },
  },
  data () {
    return {
      display: false,
      activeTab: TAB_PICK_DATE,
      date: DEFAULT_DATE,
      time: DEFAULT_TIME,
      selectedDate: '',
      selectedTime: '',
    };
  },
  mounted () {
    this.init();
  },
  computed: {
    dateTimeFormat () {
      return this.dateFormat + ' ' + this.timeFormat;
    },
    selectedDatetime () {
      if (!this.selectedDate || !this.selectedTime) {
        return null;
      }

      const datetimeString = this.selectedDate + ' ' + this.selectedTime;
      return this.$moment(datetimeString).format(this.dateTimeFormat);
    },
    dateSelected () {
      return !this.date;
    },
  },
  methods: {
    init () {
      if (!this.datetime) {
        return;
      }

      this.selectedDate = this.$moment(this.datetime).format(DEFAULT_DATE_FORMAT);
      this.selectedTime = this.$moment(this.datetime).format(DEFAULT_TIME_FORMAT);

      this.date = this.selectedDate;
      this.time = this.selectedTime;
    },
    okHandler () {
      this.selectedDate = this.date;
      this.selectedTime = this.time;
      this.resetPicker();
      this.$emit('input', this.selectedDatetime);
    },
    clearHandler () {
      this.resetPicker();
      this.date = DEFAULT_DATE;
      this.time = DEFAULT_TIME;
      this.$emit('input', null);
    },
    cancelHandler () {
      this.resetPicker();
      this.date = this.selectedDate;
      this.time = this.selectedTime;
      this.$emit('input', this.datetime);
    },
    resetPicker () {
      this.display = false;
      this.activeTab = TAB_PICK_DATE;
      if (this.$refs.timer) {
        this.$refs.timer.selectingHour = true;
      }
    },
    showTimePicker () {
      this.activeTab = TAB_PICK_TIME;
    },
    openDialog () {
      this.init();
      this.display = true;
      this.activeTab = true;
    },
  },
  watch: {
    datetime: function () {
      this.init();
    },
  },
};
</script>
