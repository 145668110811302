import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import constant from '@/configs/constant';

const getDefaultState = () => {
  return {
    user: {},
    token: '',
  };
};

const state = getDefaultState();
const getters = {
  user (state) {
    return state.user;
  },
  token (state) {
    return state.token;
  },
  excludeTargetInputs (state) {
    if (!Object.keys(state.user).length) return ['system', 'client', 'company', 'store'];

    if (state.user.user_type === constant.user.type.USER_TYPE_GS_ADMIN) { return []; }

    if (state.user.user_type === constant.user.type.USER_TYPE_CLIENT_MANAGER) { return ['system', 'client']; }

    if (state.user.user_type === constant.user.type.USER_TYPE_COMPANY_MANAGER) { return ['system', 'client', 'company']; }

    if (state.user.user_type === constant.user.type.USER_TYPE_STORE_MANAGER) { return ['system', 'client', 'company', 'store']; }

    return ['system', 'client', 'company', 'store'];
  },
  isGSAdmin (state) {
    return state.user.user_type === constant.user.type.USER_TYPE_GS_ADMIN;
  },
  isClientManager (state) {
    return state.user.user_type === constant.user.type.USER_TYPE_CLIENT_MANAGER;
  },
  isCompanyManager (state) {
    return state.user.user_type === constant.user.type.USER_TYPE_COMPANY_MANAGER;
  },
  isStoreManager (state) {
    return state.user.user_type === constant.user.type.USER_TYPE_STORE_MANAGER;
  },
};
const mutations = {
  SET_USER (state, { token, user }) {
    state.token = token;
    state.user = user;
  },
  UNSET_USER (state) {
    state.token = '';
    state.user = {};
  },
};
const actions = {
  async login ({ commit }, payload) {
    const resLogin = await http.post(apiEndpoints.login, payload);
    const { status, data } = resLogin;

    if (status) {
      const { token, user } = data.result;

      commit('SET_USER', {
        token,
        user,
      });

      return {
        status: true,
      };
    } else {
      return {
        status: false,
        errors: data.errors,
        code: data.code,
        message: data.message,
      };
    }
  },
  async logout ({ commit }) {
    await http.post(apiEndpoints.logout);
    commit('UNSET_USER');
    return true;
  },
  async getListUser (_, payload) {
    return await http.get(apiEndpoints.users, payload);
  },
  async deleteUser (_, id) {
    return await http.delete(apiEndpoints.user_delete.replace(':id', id));
  },
  async importUsers (_, payload) {
    return await http.formUploadWithoutLoading(apiEndpoints.import_user, {
      file: payload,
    });
  },
  async createUser (_, payload) {
    return await http.post(apiEndpoints.create_user, payload);
  },
  async confirmDataCreateUser (_, payload) {
    payload.is_confirm = true;
    return await this.dispatch('userStore/createUser', payload);
  },
  async getUser (_, id) {
    return await http.get(apiEndpoints.user_get.replace(':id', id));
  },
  async updateUser (_, payload) {
    return await http.put(apiEndpoints.user_update.replace(':id', payload.id), payload);
  },
  async confirmDataUpdateUser (_, payload) {
    payload.is_confirm = true;
    return await http.put(apiEndpoints.user_update.replace(':id', payload.id), payload);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
