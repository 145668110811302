import Vue from 'vue';
import moment from 'moment';
import {
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
  extend,
} from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import i18n from '@/locales/i18n';
import * as helpers from '@/helpers';

Object.keys(rules).forEach(rule => {
  extend(rule, {
    ...rules[rule],
    message: i18n.t('validate.messages.' + rule),
  });
  extend('phone_number', {
    validate (value) {
      const regPhoneNumber = /^0[0-9]{1,3}-[0-9]{2,4}-[0-9]{3,4}$/;
      return {
        valid: regPhoneNumber.test(value) && (value.length === 12 || value.length === 13),
      };
    },
    message: i18n.t('validate.messages.error_phone_number'),
  });
  extend('range', {
    validate (value, { min, max }) {
      return value >= min && value <= max;
    },
    params: ['min', 'max'],
    message: i18n.t('validate.messages.range_number'),
  });
  extend('min_valid_value', {
    ...rules.min_value,
    message: i18n.t('validate.messages.invalid'),
  });
  extend('min_valid_use_point', {
    ...rules.min_value,
    message: i18n.t('transaction.message.total_point_invalid'),
  });
  extend('min_total_add_point', {
    params: ['total'],
    validate (value, { total }) {
      return value && Number(total || 0) > 0;
    },
    message: i18n.t('transaction.message.total_point_invalid'),
  });
  extend('multiple_value', {
    params: ['target'],
    validate (value, { target }) {
      value = Number(value || 0);
      return target && !(value % target);
    },
    message: i18n.t('validate.messages.invalid'),
  });
  extend('required_if_total_add_point_invalid', {
    ...rules.required_if,
    message: i18n.t('transaction.message.total_point_invalid'),
  });
  extend('half_size', {
    validate (value) {
      const chars = Array.from(value);
      for (const char of chars) {
        const width = helpers.mbCharwidth(char);
        if (width !== 1) {
          return false;
        }
      }
      return true;
    },
    message: i18n.t('validate.messages.half_size'),
  });
  extend('scope_valid', {
    validate (value) {
      return value;
    },
    message: i18n.t('validate.messages.invalid'),
  });
  extend('end_time_after', {
    validate (value, { target }) {
      if (!target) {
        return true;
      }
      const valueDateTime = moment(value);
      const targetDateTime = moment(target);
      return valueDateTime > targetDateTime;
    },
    params: ['target'],
    message: i18n.t('validate.messages.end_time_after'),
  });
  extend('integer_with_field', {
    ...rules.integer,
    message: i18n.t('validate.messages.integer_with_field'),
  });
  extend('half_size_character', {
    validate (value) {
      console.log(value);
      return /^[a-zA-Z0-9+!@#$%^&*()_+-=[\]{};'":\\|,.<>/?]+$/.test(value);
    },
    message: i18n.t('validate.messages.half_size_character'),
  });
});

setInteractionMode('passive');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
