import store from '@/store';

export default {
  async putFormUpload (url, params = {}) {
    try {
      store.dispatch('setIsShowLoading', true);
      const result = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        body: params,
      });
      store.dispatch('setIsShowLoading', false);
      return result;
    } catch (error) {
      store.dispatch('setIsShowLoading', false);
      return error;
    }
  },

};
