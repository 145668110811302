export default {
  breadcrumbs: {
    invoice: '請求書発行機能',
  },
  label: {
    date_time_select: '日付選択',
    invoice_created_at: '請求締日',
    payment_deadline: '支払期日',
    status_select: 'ステータス選択',
  },
  table: {
    merchant_code: '加盟店コード',
    partner_company: '提携企業名',
    partner_store: '提携店名',
    payment_amount: '精算金額(税込)',
    invoice_created_at: '請求締日',
    payment_deadline: '支払期日',
    note: '備考',
    is_downloaded: 'ダウンロード状況',
    download: 'ダウンロード',
    upload_file: 'ファイルを更新する',
    action: 'アクション',
    status: 'ステータス',
  },
  checkbox: {
    download_complete: 'ダウンロード済み',
    not_downloaded: '未ダウンロー ド',
  },
  dialog: {
    upload: {
      message: '新しい請求書データを反映します。よろしいでしょうか。',
      message_success: '反映が完了しました。',
    },
    delete: {
      message_confirm: '請求書を削除してもよろしいでしょうか。',
      message_success: '削除が完了しました。',
    },
    import_zip: {
      title: 'インポートファイルを選択 (zip)',
      message_confirm_upload: '請求書データをアップロードします。よろしいですか。',
      message_import_success: 'インポートが完了しました。',
      message_null_file_name: 'ファイルの名前を入力してください。',
    },
  },
  button: {
    invoice_import: '請求書のインポート',
    upload: 'アップロード',
  },
  status: {
    processing: '実施中',
    success: '成功',
    fail: '失敗',
  },
};
