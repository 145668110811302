export default {
  breadcrumbs: {
    set_tax: '税率設定',
  },
  label: {

  },
  message: '最大 5 つの税率がある',
  button: {
    set_tax_rate: '税率設定',
  },
  dialog_delete: {
    message: '税率を削除してもよろしいでしょうか。',
  },
};
