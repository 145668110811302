export default {
  login: {
    title: 'ログイン',
    button: {
      login: 'ログイン',
    },
    label: {
      login_id: 'ログインID',
      password: 'パスワード',
    },
    messages: {
      fail: 'ログインID、パスワードをご確認ください。',
    },
  },
};
