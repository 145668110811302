<template>
  <v-app>
    <component :is="layout">
      <router-view :key="$route.fullPath" />
    </component>
    <snack-bar></snack-bar>
    <progress-circular v-if="isShowLoading"></progress-circular>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

const DEFAULT_LAYOUT = 'admin';
export default {
  computed: {
    ...mapGetters({
      isShowLoading: 'isShowLoading',
    }),
    layout () {
      return (this.$route.meta.layout || DEFAULT_LAYOUT) + '-layout';
    },
  },
};
</script>
