export default {
  breadcrumb: {
    list: 'システム一覧',
    create: 'システム登録',
    edit: 'システム変更',
  },
  label: {
    system_name: 'システム名',
    system_code: 'システムコード',
    file_format: 'ファイルフォーマット',
    api: 'API',
  },
  button: {
    register: 'システム登録',
  },
  table: {
    system_id: 'システム ID',
    system_name: 'システム名',
    system_code: 'システムコード',
    file_format: 'ファイルフォーマット',
    api: 'API',
  },
  api_available: {
    available: '有',
    no_available: 'なし',
  },
};
