import i18n from '@/locales/i18n';

const constant = {
  download_status: {
    DOWNLOADED: 1,
    NOT_DOWNLOADED: 0,
  },
  service_type_options: [
    {
      value: 1,
      text: i18n.t('store.checkbox.device_4g'),
    },
    {
      value: 2,
      text: i18n.t('store.checkbox.in_store_web'),
    },
    {
      value: 3,
      text: i18n.t('store.checkbox.file_link'),
    },
  ],
  status_options: [
    {
      value: 1,
      text: i18n.t('store.options.active'),
    },
    {
      value: 0,
      text: i18n.t('store.options.inactive'),
    },
  ],
  status: {
    ACTIVE: 1,
    INACTIVE: 0,
  },

  import_type: {
    SINGLE: 'SINGLE',
    BULK: 'BULK',
  },
};

export default constant;
