import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();
const actions = {
  async cancelImport (_, { id }) {
    return await http.post(apiEndpoints.cancel_import_manual_point_csv.replace(':manual_import', id));
  },
};
export default {
  state,
  actions,
};
