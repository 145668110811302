import Vue from 'vue';
import InitLayout from '@/layouts/Init.vue';
import AdminLayout from '@/layouts/Admin.vue';
import AuthLayout from '@/layouts/Auth.vue';
import SvgIcon from '@/components/common/SvgIcon.vue';
import Dialog from '@/components/common/Dialog.vue';
import DatePicker from '@/components/common/DatePicker.vue';
import DateTimePicker from '@/components/common/DateTimePicker.vue';
import Snackbar from '@/components/common/Snackbar.vue';
import Pagination from '@/components/common/Pagination.vue';
import ProgressCircular from '@/components/common/ProgressCircular.vue';
import SectionBox from '@/components/common/SectionBox.vue';

Vue.component('init-layout', InitLayout);
Vue.component('admin-layout', AdminLayout);
Vue.component('auth-layout', AuthLayout);
Vue.component('svg-icon', SvgIcon);
Vue.component('admin-dialog', Dialog);
Vue.component('date-picker', DatePicker);
Vue.component('date-time-picker', DateTimePicker);
Vue.component('snack-bar', Snackbar);
Vue.component('admin-pagination', Pagination);
Vue.component('progress-circular', ProgressCircular);
Vue.component('section-box', SectionBox);
