import i18n from '@/locales/i18n';
import user from '@/configs/user';
import promotion from '@/configs/promotion';
import transaction from '@/configs/transaction';
import store from '@/configs/store';
import system from '@/configs/system';
import maintenance from '@/configs/maintenance';
import invoice from '@/configs/invoice';
import log from '@/configs/log';
import manualImport from '@/configs/manual_import';
import information from '@/configs/information';

const constant = {
  per_page_default: 20,
  SORT_DESC: 'DESC',
  SORT_ASC: 'ASC',
  max_file_size: 5242880, // 5MB
  image_types: ['jpg', 'jpeg', 'png'],
  pagination_total_visible: 7,
  perpage_max: 1000,
  pagination_options: [
    {
      text: 20,
      value: 20,
    },
    {
      text: 50,
      value: 50,
    },
    {
      text: 100,
      value: 100,
    },
  ],
  checkbox_values: {
    true: 1,
    false: 0,
  },
  step_input: 1,
  step_confirm: 2,
  step_complete: 3,
  months: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  user: user,
  promotion: promotion,
  transaction: transaction,
  store: store,
  system: system,
  maintenance: maintenance,
  invoice: invoice,
  log: log,
  manual_import: manualImport,
  information: information,
  per_page_options: [20, 50, 100],
  days_of_week: [
    {
      value: 1,
      text: '月曜日',
    },
    {
      value: 2,
      text: '火曜日',
    },
    {
      value: 3,
      text: '水曜日',
    },
    {
      value: 4,
      text: '木曜日',
    },
    {
      value: 5,
      text: '金曜日',
    },
    {
      value: 6,
      text: '土曜日',
    },
    {
      value: 0,
      text: '日曜日',
    },
  ],
  status_options: [
    {
      value: 0,
      text: i18n.t('common.options.inactive'),
    },
    {
      value: 1,
      text: i18n.t('common.options.active'),
    },
  ],
  status: {
    ACTIVE: 1,
    INACTIVE: 0,
  },
  companies_status_options: [
    {
      value: 1,
      text: 'オープン',
    },
    {
      value: 0,
      text: 'クローズ',
    },
  ],
  import_screen_company: 'company',
  cd_formula_options: [
    {
      value: 1,
      text: 'モジュラス10',
    },
    {
      value: 2,
      text: 'モジュラス11',
    },
    {
      value: 3,
      text: 'モジュラス10 ウェイト3',
    },
    {
      value: 4,
      text: 'モジュラス10 ウェイト2',
    },
  ],
  barcode_types: [
    {
      value: 'NW7',
      text: 'NW7',
    },
    {
      value: 'JAN',
      text: 'JAN',
    },
    {
      value: 'CODE128',
      text: 'Code128',
    },
    {
      value: 'JISII',
      text: 'JISII',
    },
    {
      value: 'QRCODE',
      text: 'QRコード',
    },
  ],
  send_receive_options: [
    {
      value: 0,
      text: 'お客様がダウンロード',
    },
    {
      value: 1,
      text: 'アップロード',
    },
  ],
  protocol_options: [
    {
      value: 'FTP',
      text: 'FTP',
    },
    {
      value: 'SFTP',
      text: 'SFTP',
    },
  ],
  card_type: {
    individual: 'INDIVIDUAL',
    range: 'RANGE',
  },
  operation_types: [
    {
      value: 'AUTH',
      text: '認証',
    },
    {
      value: 'SYSTEM',
      text: 'システム',
    },
    {
      value: 'CLIENT',
      text: 'クライアント',
    },
    {
      value: 'COMPANY',
      text: '提携企業',
    },
    {
      value: 'STORE',
      text: '提携店',
    },
    {
      value: 'USER',
      text: 'ユーザー ',
    },
  ],
  aws_s3_multi_part: {
    chunk_size: 1024 * 1024 * 32, // 32MB
    min_chunk_size: 1024 * 1024 * 5, // 5MB
  },
  invoice_status_options: [
    {
      value: null,
      text: i18n.t('common.label.status_select'),
    },
    {
      value: '0',
      text: i18n.t('invoice.status.processing'),
    },
    {
      value: '1',
      text: i18n.t('invoice.status.success'),
    },
    {
      value: '2',
      text: i18n.t('invoice.status.fail'),
    },
  ],
  editor_config: {
    versionCheck: false,
    toolbarGroups: [
      { name: 'styles', groups: ['styles'] },
      { name: 'document', groups: ['mode', 'document', 'doctools'] },
      { name: 'editing', groups: ['find', 'selection', 'spellchecker', 'editing'] },
      { name: 'basicstyles', groups: ['cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'colors', groups: ['colors'] },
      { name: 'tools', groups: ['tools'] },
      { name: 'others', groups: ['others'] },
    ],
    removePlugins: 'stylescombo',
    removeButtons: 'FontFamily,Image,Source,Cut,Copy,Paste,Maximize,About,PasteFromWord,PasteText,HorizontalRule,SpecialChar,Anchor,Scayt',
    language: 'ja',
    extraPlugins: 'justify,colorbutton',
    format_tags: 'p;h1;h2;h3',
    format_h1: { element: 'h1', attributes: { class: 'editor__h1', style: 'font-size: 24px; font-weight: bold; margin-top: 0; margin-bottom: 0.2em;' } },
    format_h2: { element: 'h2', attributes: { class: 'editor__h2', style: 'font-size: 22px; font-weight: bold; margin-top: 0; margin-bottom: 0.2em;' } },
    format_h3: { element: 'h3', attributes: { class: 'editor__h3', style: 'font-size: 20px; font-weight: bold; margin-top: 0; margin-bottom: 0.2em;' } },
    format_h4: { element: 'h4', attributes: { class: 'editor__h4', style: 'font-size: 18px; font-weight: bold; margin-top: 0; margin-bottom: 0.2em;' } },
    format_p: { element: 'p', attributes: { class: 'editor__text', style: 'font-size: 17px; margin-top: 0; margin-bottom: 0.2em;' } },
  },
};

export default constant;
