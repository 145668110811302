var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"sidebar",class:{ collapsed: !_vm.isShowSidebar },attrs:{"id":"sidebar"}},[_c('div',{staticClass:"sidebar-content js-simplebar"},[_c('v-list',{staticClass:"mt-xl-14"},_vm._l((_vm.sidebarMenuItems),function(item,index){return _c('v-list-item',{key:index,class:{
          'sidebar-item': item.type === _vm.MENU_CHILD,
          active:
            (_vm.$route.path.includes(item.path) && item.path) ||
            (_vm.$route.name === 'home' && _vm.$route.name === item.router_name),
        }},[(item.type === _vm.MENU_PARENT)?_c('v-list-group',{staticClass:"sidebar-dropdown",attrs:{"value":false},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(_vm._s(item.name))])]},proxy:true}],null,true)},_vm._l((item.children),function(child,childIndex){return _c('v-list-item',{key:childIndex},[_c('router-link',{staticClass:"sidebar-link sidebar-link--sub",class:{
                active:
                  (_vm.$route.path.includes(item.path) && item.path) ||
                  (_vm.$route.name === 'home' &&
                    _vm.$route.name === item.router_name),
              },attrs:{"to":{ name: child.router_name, params: { ...child.params } }}},[_c('span',{staticClass:"align-middle"},[_vm._v(_vm._s(child.name))])])],1)}),1):_c('router-link',{staticClass:"sidebar-link",class:{
            'sidebar-item': item.type === _vm.MENU_CHILD,
            active:
              (_vm.$route.path.includes(item.path) && item.path) ||
              (_vm.$route.name === 'home' && _vm.$route.name === item.router_name),
          },attrs:{"to":{ name: item.router_name }}},[_c('span',{staticClass:"align-middle",domProps:{"innerHTML":_vm._s(item.name)}})])],1)}),1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }