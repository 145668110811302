export default {
  breadcrumbs: {
    daily_performance_report: '日次実績レポート',
    monthly_performance_report: '月次実績レポート',
  },
  label: {
    company_select: '企業選択',
    store_select: '店舗選択',
    month: '月',
    year: '年',
    total: '合計',
  },
  button: {
    report_by_day: '日次レポート案',
    report_by_month: '月次レポート',
    report_performance: '実績レポート',
    report_point: 'ポイント券利用',
  },
  table: {
    sale_date: '売上日',
    day_of_week: '曜日',
    store_name: '店舗名',
    purchase_amount: '購入額',
    guest_quantity: '客数',
    basic: '基本P',
    multiply: '掛率P',
    special_addition: '特別加算P',
    total: '合計P',
    use_8_percent: '利用P(8%)',
    use_10_percent: '利用P(10%)',
    use_tax_prefix: '利用P(',
    use_tax_suffix: '%)',
    internal_service: '内サービス<br>P利用分',
    point_ticket: 'ポイント券枚数',

    system_name: 'システム名',
    system_code: 'システムコード',
    client_code: 'クライアントコード',
    client_name: 'クライアント名',
    company_name: '提携企業名',
    start_date: '開始日',

    active_member: '稼働会員数',
    subtract_point: '利用合計P',

    bonus_point: 'ボーナスP',
    total_store: '全店舗合計',
    year_month: '売上月',
    year: '年',
    month: '月',
  },
};
