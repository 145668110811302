export default {
  breadcrumbs: {
    sent_batch: '送信バッチログ',
    received_batch: '受信バッチログ',
    operation: '操作ログ',
  },
  label: {
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    company_select: '提携企業選択',
    store_select: '提携店選択',
    date_select: '日付選択',
    employee_no: '会員番号',
    operation_user: '操作ユーザー',
    sent_status_selection: '送信状態選択',
    date_period: '日付検索',
  },
  table: {
    id: 'ID',
    system: 'システム',
    client: 'クライアント',
    client_name: 'クライアント名',
    partner_company: '提携企業',
    company_name: '提携企業名',
    partner_store: '提携店',
    store_name: '提携店名',
    date_time: '日時',
    status: 'ステータス',
    employee_no: '会員番号',
    transaction_no: '取引No',
    card_no: 'メニュー内容',
    transaction_datetime: '取引日時',
    purchase_amount_before_change: '購入金額(変更前)',
    purchase_amount: '購入金額',
    point_number_before_change: 'ポイント数(変更前)',
    point_nubmer: 'ポイント数',
    transaction_type: '取引タイプ 加算/利用',
    action: 'アクション',
    operation_user: '操作ユーザー',
    type: 'タイプ',
    menu_content: 'メニュー内容',
    ip_address: 'IPアドレス',
    user_agent: 'ユーザーエージェント',
    explanation: '説明',
    send_file_name: '送信ファイル名',
    file_name: 'ファイル名',
    sent_status: '送信状態',
    capture_status: '取込状態',
  },
  send_status_option: {
    sent: '成功',
    error: '失敗',
  },
  status_option: {
    success: '成功',
    error: '失敗',
  },
};
