export default {
  breadcrumb: {
    list: '手動データインポート ',
  },
  label: {

  },
  button: {
    import_point: 'ポイントのインポート',
    import_plan: '企画ファイルのインポート',
    download: 'ダウンロード',
    download_error_data: 'エラーデータ',
    cancel: '取消',
  },
  table: {
    date_time: '時間',
    client_name: 'クライアント名',
    file_name: 'ファイル名',
    total_rows: 'レコード数',
    successful_rows: '成功',
    failed_rows: '失敗',
    created_by: '操作ユーザー',
    type: 'タイプ',
    action: 'アクション',
  },
  dialog: {
    import_point: {
      title: 'ポイントのインポート',
      message: {
        confirm: 'ポイントファイルをインポートします。よろしいですか?',
        success: 'インポートが完了しました。',
      },
    },
    import_file: {
      title: '企画ファイル',
      message: {
        confirm: '企画ファイルをインポートします。よろしいですか?',
        success: 'インポートが完了しました。',
      },
    },
  },
  type: {
    point: 'ポイント',
    plan: '企画',
  },
};
