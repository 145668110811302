const constant = {
  type: {
    add: 'ADD',
    multiply: 'MULTIPLY',
  },
  target_type: {
    store: 'STORE',
    company: 'COMPANY',
  },
  status: {
    in_progress: 2,
    complete: 3,
  },
  max_conditions: 10,
  limit: {
    MIN_ADD: 1,
    MAX_ADD: 99999,
    MIN_MULTIPLY: 2,
    MAX_MULTIPLY: 99,
  },
  event_color: {
    MULTIPLY: '#0dc3f5', // blue
    ADD: '#f5ab0d', // orange
    MULTIPLY_BARCODE: '#d600f2', // purple
    ADD_BARCODE: '#fd5e83', // pink
  },
};

export default constant;
