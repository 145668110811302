<template>
  <div class="wrapper" id="main-app">
    <admin-sidebar
      v-if="isAuthenticated"
      @toggleSidebar="onToggleSidebar"
      :isShowSidebar="isShowSidebar"
    ></admin-sidebar>
    <div class="main">
      <admin-header
        v-if="isAuthenticated"
        @toggleSidebar="onToggleSidebar"
        :isShowSidebar="isShowSidebar"
      ></admin-header>
      <main class="content">
        <router-view :key="$route.fullPath"></router-view>
      </main>
    </div>
  </div>
</template>

<script>
import AdminHeader from '@/layouts/admin/Header.vue';
import AdminSidebar from '@/layouts/admin/Sidebar.vue';

export default {
  name: 'AdminLayout',
  components: {
    AdminHeader,
    AdminSidebar,
  },
  data () {
    return {
      isShowSidebar: true,
    };
  },
  computed: {
    isAuthenticated () {
      return !!this.$store.state.userStore.token;
    },
  },
  methods: {
    onToggleSidebar (value) {
      this.isShowSidebar = value;
    },
  },
};
</script>
