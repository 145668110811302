import i18n from '@/locales/i18n';

const constant = {
  status_options: [
    {
      value: 1,
      text: i18n.t('maintain.status.on'),
    },
    {
      value: 0,
      text: i18n.t('maintain.status.off'),
    },
  ],
  status: {
    ON: 1,
    OFF: 0,
  },
  DEFAULT_COLUMN_SORT: 'status',
  SCOPE_ALL: 'ALL',
  SCOPE_SYSTEM: 'SYSTEM',
  SCOPE_CLIENT: 'CLIENT',
  SCOPE_COMPANY: 'COMPANY',
};

export default constant;
