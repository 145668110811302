import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
  };
};

const state = getDefaultState();
const actions = {
  async importCompanies (_, payload) {
    return await http.formUploadWithoutLoading(apiEndpoints.import_company, {
      file: payload,
    });
  },
};
export default {
  state,
  actions,
};
