<template>
  <nav class="navbar navbar-expand navbar-light navbar-bg">
    <a class="sidebar-toggle" href="javascript:void(0)" @click="clickMenu">
      <i class="hamburger align-self-center"></i>
    </a>

    <img class="logo" src="@/assets/images/img_logo.png" />

    <div class="text-right d-flex justify-end align-center fill-width">
      <!-- <v-avatar color="green" class="mr-3" size="36">
        <v-icon dark> mdi-account-circle </v-icon>
      </v-avatar> -->

      <div class="username">{{ authUserName }}</div>

      <!-- <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="btn-setting" v-bind="attrs" v-on="on">
            <v-icon dark> mdi-cog </v-icon>
          </v-btn>
        </template>
        <div class="dropdown-content">
          <span class="mr-5">{{ $t('common.label.receive_mail') }}</span>
          <v-switch
            inset
            dense
            color="success"
            hide-details
            class="mt-0"
          ></v-switch>
        </div>
      </v-menu> -->

      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="btn-logout"
            @click="logout"
            color="blue-grey"
            dark
            v-bind="attrs"
            v-on="on"
            icon
          >
            <i class="mdi mdi-exit-to-app"></i>
          </v-btn>
        </template>
        <span>{{ $t('common.label.logout') }}</span>
      </v-tooltip>
    </div>
    <!-- dialog confirm logout -->
    <admin-dialog ref="dialog_logout">
      <!-- <template slot="header">
        <span>{{ $t('common.dialog_delete.title') }}</span>
      </template> -->
      <template>
        <p class="">{{ $t('common.dialog_logout.message') }}</p>
      </template>
      <template slot="footer">
        <div class="d-flex fill-width justify-center fill-width">
          <v-btn
            color="primany"
            class="mr-4"
            depressed
            outlined
            small
            @click="$refs.dialog_logout.close()"
          >
            {{ $t('common.button.cancel') }}
          </v-btn>
          <v-btn outlined small color="green" @click="logout">
            {{ $t('common.button.confirm') }}
          </v-btn>
        </div>
      </template>
    </admin-dialog>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'AdminHeader',
  props: ['isShowSidebar'],
  methods: {
    clickMenu () {
      this.$emit('toggleSidebar', !this.isShowSidebar);
    },
    async logout () {
      await this.dispatchLogout();
      this.$router.push({ name: 'login' });
    },
    ...mapActions({
      dispatchLogout: 'userStore/logout',
    }),
  },
  computed: {
    ...mapGetters({
      authUser: 'userStore/user',
    }),
    authUserName () {
      if (!this.authUser) return '';

      return this.authUser.user_name;
    },
  },
};
</script>

<style scoped>
.dropdown-content {
  padding: 20px 5px 20px 15px;
  min-width: 150px;
  display: flex;
  align-items: center;
}
</style>
