import i18n from '@/locales/i18n';
const constant = {
  type: {
    USER_TYPE_GS_ADMIN: 1,
    USER_TYPE_CLIENT_MANAGER: 2,
    USER_TYPE_COMPANY_MANAGER: 3,
    USER_TYPE_STORE_MANAGER: 4,
    USER_TYPE_GENERAL: 5,
    USER_TYPE_4G: 6,
  },
  types: [
    {
      value: 1,
      text: i18n.t('user.user_types.1'),
    },
    {
      value: 2,
      text: i18n.t('user.user_types.2'),
    },
    {
      value: 3,
      text: i18n.t('user.user_types.3'),
    },
    {
      value: 4,
      text: i18n.t('user.user_types.4'),
    },
    {
      value: 5,
      text: i18n.t('user.user_types.5'),
    },
    {
      value: 6,
      text: i18n.t('user.user_types.6'),
    },
  ],
  default_user_sort_column: 'created_at',
  statuses: [
    {
      value: 1,
      text: i18n.t('user.user_status.active'),
    },
    {
      value: 0,
      text: i18n.t('user.user_status.inactive'),
    },
  ],
};

export default constant;
