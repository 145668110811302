export default {
  breadcrumb: {
    list: '提携店一覧 ',
    register: '提携店登録',
    edit: '提携店変更',
  },
  label: {
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    partner_company_select: '提携企業選択',
    system: 'システム',
    client: 'クライアント',
    partner_company: '提携企業',
    partner_store_name: '提携店名',
    merchant_code: '加盟店コード(提携店)',
    store_code: '店舗コード',
    store_conversion_code: 'システム変換コード',
    status: 'ステータス',
    service_rate: 'サービスレート',
    amount: '円',
    point: 'ポイント',
    limit_point_by_transaction: 'ポイント発行制限(取引)',
    limit_point_by_day: 'ポイント発行制限(日次)',
    limit_point_by_month: 'ポイント発行制限(月次)',
    point_usage_unit: 'ポイント利用単位',
    used_service: '利用サービス',
    service_point: 'サービスポイント',
    current_service_point: '現在サービスポイント ',
    service_point_add: 'サービスポイント(加算)',
    start_date: '開始日',
  },
  checkbox: {
    device_4g: '4G端末',
    in_store_web: 'IN STORE WEB',
    file_link: 'ファイル連携',
  },
  button: {
    register: '提携店登録',
    service_point_input: 'サービスポイント投入',
  },
  table: {
    partner_store_name: '提携店名',
    store_code: '店舗コード',
    merchant_code: '加盟店コード(提携店)',
    service_used: '利用サービス',
    status: 'ステータス',
    start_date: '開始日',
    service_rate: 'サービスレート',
  },
  modal: {
    delete: {
      message: 'こちらの提携店を削除してもよろしいですか。',
    },
  },
  export: {
    file_name: '提携店一覧',
  },
  // TODO: update jp text
  options: {
    active: 'オープン',
    inactive: 'クローズ',
  },
};
