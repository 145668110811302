import i18n from '@/locales/i18n';

const constant = {
  type_options: [
    {
      value: 1,
      text: i18n.t('manual_import.type.point'),
    },
    {
      value: 2,
      text: i18n.t('manual_import.type.plan'),
    },
  ],
  type: {
    POINT: 1,
    PLAN: 2,
  },
  DEFAULT_COLUMN_SORT: 'created_at',
};

export default constant;
