<template>
  <main class="d-flex fill-width bg-light-gray">
    <slot />
  </main>
</template>

<script>
export default {
  name: 'AuthLayout',
  components: {},
  created () {
    console.log('cicd implimented');
  },
};
</script>
<style scoped>
.bg-light-gray {
  background: #F5F7FB;
}
</style>
