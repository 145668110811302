import i18n from '@/locales/i18n';

const constant = {
  send_status_options: [
    {
      value: 'SENT',
      text: i18n.t('log.send_status_option.sent'),
    },
    {
      value: 'ERROR',
      text: i18n.t('log.send_status_option.error'),
    },
  ],
  status_options: [
    {
      value: 'SUCCESS',
      text: i18n.t('log.status_option.success'),
    },
    {
      value: 'ERROR',
      text: i18n.t('log.status_option.error'),
    },
  ],
  status_receive_options: [
    {
      value: 'SUCCESS',
      text: i18n.t('log.status_option.success'),
    },
    {
      value: 'FAILED',
      text: i18n.t('log.status_option.error'),
    },
  ],
};

export default constant;
