import i18n from '@/locales/i18n';

const constant = {
  important_options: [
    {
      value: '1',
      text: i18n.t('information.important.low'),
    },
    {
      value: '2',
      text: i18n.t('information.important.normal'),
    },
    {
      value: '3',
      text: i18n.t('information.important.high'),
    },
    {
      value: '4',
      text: i18n.t('information.important.urgent'),
    },
  ],
  status_options: [
    {
      value: '1',
      text: i18n.t('information.status.waiting_approve'),
    },
    {
      value: '2',
      text: i18n.t('information.status.approved'),
    },
    {
      value: '3',
      text: i18n.t('information.status.rejected'),
    },
  ],
  important: {
    LOW: '1',
    NORMAL: '2',
    HIGH: '3',
    URGENT: '4',
  },
  status: {
    WAITING_APPROVE: 1,
    APPROVED: 2,
    REJECTED: 3,
  },
  DEFAULT_COLUMN_SORT: 'created_at',
  SCOPE_ALL: 'ALL',
  SCOPE_SYSTEM: 'SYSTEM',
  SCOPE_CLIENT: 'CLIENT',
  SCOPE_COMPANY: 'COMPANY',

  UPLOAD_STATUS_PROCESSING: 1,
  UPLOAD_STATUS_COMPLETED: 2,
  UPLOAD_STATUS_FAILED: 3,
  MAXIMUM_ATTACHMENTS: 3,

  TIMEOUT_FIRST_TIME_CHECK_STATUS: 15000, // Millisecond
  SCHEDULE_TIME_CHECK_STATUS: 5000, // Millisecond
  TIMEOUT_CANCEL_CHECK_STATUS: 300000, // Millisecond
};

export default constant;
