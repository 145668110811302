<template>
  <div class="icon" @click="onClickIcon" :class="classList">
    <img :src="iconPath" :alt="icon"/>
  </div>
</template>
<script>
export default {
  name: 'SvgIcon',
  props: {
    icon: { type: String, require: false, default: 'user' },
    actived: { type: Boolean, require: false, default: false },
    classList: { type: [String, Object, Array], require: false, default: () => { return []; } },
  },
  computed: {
    iconPath () {
      const icon = this.actived ? `${this.icon}_active` : this.icon;

      return require(`@/assets/icons/ico_${icon}.svg`);
    },
  },
  methods: {
    onClickIcon () {
      this.$emit('click');
    },
  },
};
</script>
<style scoped>
.icon {
  display: inline-flex;
  transition: .3s all;
}
</style>
