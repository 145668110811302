export default {
  breadcrumbs: {
    list: 'プロモーション一覧',
    edit: 'プロモーション変更',
    register: 'プロモーション登録',
  },
  label: {
    status: 'ステータス',
    client_select: 'クライアント選択',
    partner_company_select: '提携企業選択',
    partner_store_select: '提携店選択',
    target_period: '対象期間',
    status_select: 'ステータス選択',
    point: 'ポイント',
    target_store: '対象店舗',
    promotion_name: 'プロモーション名',
    code: 'コード',
    promotion_code: 'プロモーションコード',
    date: '日付',
    set_condition: '条件の設定',
    type: '区分',
    start_date: '開始日',
    end_date: '終了日',
    weekday: '曜日別',
    purchase_amount: '購入金額',
    value: '値',
    calendar: 'プロモーションカレンダー',
    target_count: '選ばれた{count}の提携店',
  },
  checkbox: {
    generate_barcode: 'バーコード生成する',
    monday: '月曜日',
    tuesday: '火曜日',
    wednesday: '水曜日',
    thursday: '木曜日',
    friday: '金曜日',
    saturday: '土曜日',
    sunday: '日曜日',
  },
  button: {
    register: '新規登録',
    partner_store_select: '提携店選択',
  },
  table: {
    project_code: '企画コード',
    promotion_name: 'プロモーション名',
    target_store: '対象店舗',
    status: 'ステータス',
    start_date: '開始日',
    end_date: '終了日',
    promotion_code: 'プロモーションコード',
    store_name: '提携店名',
    store_code: '提携店コード',
    used_services: '利用サービス',
  },
  modal: {
    delete: {
      current_use: 'こちらのプロモーションは現在実施中です。',
      message: 'こちらのプロモーションを削除してもよろしいですか。',
    },
    download: {
      title: 'ダウンロードの確認',
      message: 'こちらのバーコードをダウンロードしてもよろしいですか。',
    },
  },
  target_type: {
    company: '全店舗',
    store: '店舗指定',
  },
  status: {
    in_progress: '有効',
    complete: '無効',
  },
  type: {
    add: '加算',
    multiply: '掛率',
  },
  placeholder: {
    promotion_type: '区分選択',
  },
};
