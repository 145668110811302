import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';
import apiThirdParty from '@/services/apiThirdParty';

const getDefaultState = () => {
  return {
    //
  };
};

const state = getDefaultState();
const getters = {
  //
};

const mutations = {
  //
};

const actions = {
  async completedUpload (_, payload) {
    return await http.post(apiEndpoints.multipart.completed, payload);
  },
  async startUpload (_, payload) {
    return await http.post(apiEndpoints.multipart.start, payload);
  },
  async partUpload (_, { url, payload }) {
    return await apiThirdParty.putFormUpload(url, payload);
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
