export default {
  breadcrumbs: {
    list: '取引一覧 ',
    register: '取引管理登録',
    edit: '取引訂正',
    detail: '取引詳細',
    confirm: '確認',
    complete: '取引訂正完了',
    confirm_edit: '取引訂正確認',
  },
  label: {
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    partner_company_select: '提携企業選択',
    partner_store_select: '提携店選択',
    user_type_select: 'ユーザー区分選択',
    search_date_from: '日付検索（から）',
    search_date_to: '日付検索（まで）',
    child_transaction_id: '派生取引ID : {transaction_id}',
    source_transaction_id: '親取引ID : {transaction_id}',
    store_code: '提携店コード',
    transaction_id: '取引ID',
    card_number: 'カード番号',
    system: 'システム',
    client: 'クライアント',
    partner_company: '提携企業',
    partner_store: '提携店',
    basic_point: '基本ポイント',
    bonus_point: 'ボーナスポイント',
    multiply_point: '掛率ポイント',
    add_point: '加算ポイント',
    total_bonus_point: 'ボーナスポイント合計',
    total_added_point: 'ポイント加算合計',
    purchase_amount: '購入金額',
    transaction_datetime: '取引日時',
    type: '取引区分',
    user_name: 'ユーザー',
    status: 'ステータス',
    sync_datetime: '同期日時',
    user_point_before_transaction: '処理前ポイント残高',
    total_use_point: 'ポイント利用合計',
    use_point_tax: '税{tax_rate}%分',
    multiply_rate: '掛率',
    addition: '加算',
    current_balance: '現在ポイント',
    use_point: 'ポイント利用',
    use_point_note: '（{unit_point}ポイント単位）',
    date_period: '日付検索',
  },
  button: {
    export_cs21: 'CS21用出力ボタン',
    edit_multiply_point: '特別掛率',
    edit_add_point: '特別加算',
  },
  table: {
    transaction_id: '取引ID',
    card_number: 'カード番号',
    total_point: 'ポイント数',
    amount: '購入金額',
    transaction_datetime: '取引日時',
    transaction_type: '取引区分',
    operation_user: '操作ユーザー名',
    status: 'ステータス',
  },
  dialog: {
    cancel: {
      title: '確認',
      message_confirm: 'この取引をキャンセルしてもよろしいですか。',
      message_success: 'キャンセルが完了しました。',
    },
    add_point: {
      title: '特別加算',
    },
    multiply_point: {
      title: '特別掛率',
    },
    use_point: {
      title: '',
    },
    cancel_confirm: {
      message: 'この取引を取消してもよろしいですか。',
    },
    cancel_complete: {
      message: '取消が完了しました。',
    },
  },
  total_usage_point: '当月ポイント加算合計<b>: {point}</b> ポイント',
  sum_service_point: 'サービスポイント残高: <b>{service_point}</b>ポイント',
  message: {
    edit_note: '修正する値を入力してください',
    edit_confirm_note: '利用ポイントに誤りがあります。ご確認ください。',
    confirm_modify: 'この内容で修正してもよろしいですか？',
    transaction_updated: '訂正が完了しました。',
    confirm_cancel: 'この取引を取消してもよろしいですか。',
    total_use_point_invalid: 'ポイント利用合計が有効ではありません。',
    total_add_point_invalid: '入力内容に誤りがあります。',
    total_point_invalid: '入力内容に誤りがあります。',
    transaction_complete: 'Transaction complete',
    transaction_add_complete: 'Transaction Add complete',
    transaction_use_complete: 'Transaction Use complete。',
    transaction_update: '訂正が完了しました。',
    confirm_reset: 'このボーナスポイントをリセットしてもよろしいですか。',
  },
};
