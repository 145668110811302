import http from '@/services/http';
import apiEndpoints from '@/services/apiEndpoints';

const getDefaultState = () => {
  return {
    newSystemAddConfirmData: {},
  };
};

const state = getDefaultState();
const getters = {
  newSystemAddConfirmData: state => state.newSystemAddConfirmData,
};

const mutations = {
  SET_SYSTEM_ADD_CONFIRM_DATA (state, payload) {
    state.newSystemAddConfirmData = payload;
  },
};

const actions = {
  async create (_, payload) {
    return await http.post(apiEndpoints.create_system, payload);
  },
  resetConfirmData ({ commit }) {
    commit('SET_SYSTEM_ADD_CONFIRM_DATA', {});
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};
