const namespaced = true;
const state = {
  success: 0,
  error: 0,
  message: '',
};
const mutations = {
  ADD_SUCCESS (state, payload) {
    state.message = payload || '';
    state.success += 1;
  },
  ADD_ERROR (state, payload) {
    state.message = payload || '';
    state.error += 1;
  },
  RESET_ERROR (state) {
    state.error = [];
  },
};
const getters = {
  success: (state) => state.success,
  error: (state) => state.error,
  message: (state) => state.message,
};

const actions = {
  addSuccess ({ commit }, payload = null) {
    commit('ADD_SUCCESS', payload);
  },
  addError ({ commit }, payload = null) {
    commit('ADD_ERROR', payload);
  },
};
export default {
  namespaced,
  state,
  mutations,
  getters,
  actions,
};
