export default {
  breadcrumbs: {
    list: 'ユーザ一覧 ',
    register: 'ユーザー登録',
    edit: 'ユーザー変更',
  },
  label: {
    user_type: 'ユーザー区分',
    system: 'システム',
    client: 'クライアント',
    partner_company: '提携企業',
    partner_store: '提携店',
    user_id: 'ユーザーID',
    user_name: 'ユーザー名',
    user_password: 'ユーザーPW',
    email: 'メールアドレス',
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    partner_company_select: '提携企業選択',
    partner_store_select: '提携店選択',
    user_type_select: 'ユーザー区分選択',
    status: 'ステータス',
    start_date: '開始日',
  },
  button: {
    register: 'ユーザ一登録',
    generation: '生成',
    select_file: 'ファイルを選択',
  },
  table: {
    user_id: 'ユーザーID',
    user_name: 'ユーザー名',
    email: 'メールアドレス',
    status: 'ステータス',
    start_date: '開始日',
    user_type: 'ユーザー区分',
  },
  radio: {
    neccessary: '要',
    unneccessary: '不要',
  },
  modal: {
    delete: {
      message: 'こちらのユーザーを削除してもよろしいですか。',
    },
  },
  user_types: {
    1: 'システム管理者（GS）',
    2: '企業管理者',
    3: '本部管理者',
    4: '店舗管理者',
    5: '一般ユーザー',
    6: '4G端末',
  },
  user_status: {
    active: '有効',
    inactive: '休止',
  },
  form_import: {
    title: 'インポートファイルを選択 (CSV)',
    confirm: 'ユーザデータをアップロードします。よろしいですか。',
    success: 'インポートが完了しました。',
  },
};
