export default {
  breadcrumb: {
    list: '提携企業一覧 ',
    register: '提携企業登録',
    edit: '提携企業変更',
  },
  label: {
    system: 'システム',
    client: 'クライアント',
    partner_company_name: '提携企業名',
    merchant_code: '加盟店コード(提携企業)',
    company_code: '企業コード',
    partner_company_code: '提携企業コード',
    status: 'ステータス',
    system_select: 'システム選択',
    client_select: 'クライアント選択',
    point_version: 'ポイント版',
    point_ticket: 'ポイント券',
    show_hide_setting: '表示・非表示設定',
  },
  button: {
    register: '提携企業登録',
    select_file: 'ファイルを選択',
  },
  table: {
    system_name: 'システム名',
    client_name: 'クライアント名',
    partner_company_code: '提携企業コード',
    partner_company_name: '提携企業名',
    merchant_code: '加盟店コード(提携企業)',
    status: 'ステータス',
  },
  modal: {
    delete: {
      message: 'こちらの提携企業を削除してもよろしいですか。',
    },
  },
  checkbox: {
    add_point: 'ポイント加算',
    use_point: 'ポイント利用',
    use_point_ticket: 'ポイント券利用',
  },
  form_import: {
    title: 'インポートファイルを選択 (CSV)',
    confirm: 'ユーザデータをアップロードします。よろしいですか。',
    success: 'インポートが完了しました。',
  },

};
