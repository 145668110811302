import permission from '@/configs/permission';
import useRole from '@/configs/user';
import store from '@/store';

const SCOPE_ROUTES = 'routes';
const SCOPE_FUNCTIONS = 'functions';

export default class AuthorizationService {
  permission;
  authUser = undefined;

  constructor () {
    this.permission = permission;
    if (store.state.userStore.user) {
      this.authUser = store.state.userStore.user;
    }
  }

  /**
   @var string: scope - routes or functions
   @var string: target - the route name or the function name
   @var string: routeGroup
   @return boolean
  */
  hasPermission (scope, target, routeGroup) {
    if (scope !== SCOPE_FUNCTIONS && scope !== SCOPE_ROUTES) return false;

    if (!this.validateAuthUser()) return false;

    // if (this.isSuperAdmin()) return true;

    if (scope === SCOPE_ROUTES) {
      return this.validateScopeRoute(this.resolveUserType(), routeGroup, target);
    }

    return this.validateScopeFunction(this.resolveUserType(), routeGroup, target);
  }

  validateScopeRoute (userType, routeGroup, routeName) {
    const permissionByUserType = permission[userType];

    if (!permissionByUserType) return false;

    const permissionByRouteGroup = permissionByUserType[routeGroup];

    if (!permissionByRouteGroup) return false;

    if (!permissionByRouteGroup.routes || permissionByRouteGroup.routes.length <= 0) return false;

    return permissionByRouteGroup.routes.indexOf(routeName) !== -1;
  }

  validateScopeFunction (userType, routeGroup, functionName) {
    const permissionByUserType = permission[userType];

    if (!permissionByUserType) return false;

    const permissionByRouteGroup = permissionByUserType[routeGroup];

    if (!permissionByRouteGroup) return false;

    if (!permissionByRouteGroup.functions || permissionByRouteGroup.functions.length <= 0) return false;

    return permissionByRouteGroup.functions.indexOf(functionName) !== -1;
  }

  /**
   @return array
   */
  getAllAccessibleRoutesByUser (userType = undefined) {
    if (!userType) {
      userType = this.resolveUserType();
    }
    let result = [];

    const permissionByUserType = permission[userType] ?? [];
    Object.keys(permissionByUserType).forEach(item => {
      result = [...result, ...permissionByUserType[item].routes];
    });

    return result;
  }

  validateAuthUser () {
    return !!this.authUser;
  }

  isSuperAdmin () {
    return this.authUser.user_type === useRole.type.USER_TYPE_GS_ADMIN;
  }

  resolveUserType () {
    switch (this.authUser.user_type) {
      case useRole.type.USER_TYPE_GS_ADMIN:
        return 'gs_admin';
      case useRole.type.USER_TYPE_CLIENT_MANAGER:
        return 'client_manager';
      case useRole.type.USER_TYPE_COMPANY_MANAGER:
        return 'company_manager';
      case useRole.type.USER_TYPE_STORE_MANAGER:
        return 'store_manager';
      default:
        return '';
    }
  }
}
