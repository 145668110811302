// import domToImage from 'dom-to-image';
import constant from '@/configs/constant';
import http from '@/services/http';

const domToImage = require('dom-to-image');
const moment = require('moment');

export const formatDate = (value, format = 'YYYY/MM/DD') => {
  if (value) {
    return moment(value).format(format);
  }
  return null;
};

export const getImageUrl = (path) => {
  if (path) {
    return process.env.VUE_APP_FILE_URL + '/' + path.replace('public/', '');
  }

  return null;
};

export const scrollToInvalidInput = (errorClass = 'error-msg') => {
  const el = document.querySelector(`.${errorClass}`);
  if (el) {
    el.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const replaceImgByDefault = (event) => {
  event.target.src = require('@/assets/images/img_unavailable.jpg');
  event.target.style = 'object-fit: cover !important';
};

export const generateEan13 = (cardNumber, cdStartPosition = 13) => {
  const mainPart = cardNumber.substr(0, cdStartPosition - 1);
  const mainParts = mainPart.split('').reverse();
  let total = 0;
  for (let index = 0; index < mainParts.length; index++) {
    const part = mainParts[index];
    const multi = index % 2 === 0 ? 3 : 1;
    total += part * multi;
  }

  return mainPart + (Math.ceil(total / 10) * 10 - (total % 10) - (Math.floor(total / 10) * 10));
};

export const downloadImage = async (imageSrc, filename = 'image') => {
  const a = document.createElement('a'); // Create <a>
  a.href = imageSrc; // Image Base64 Goes here
  a.download = `${filename}.png`; // File name Here
  a.click(); // Downloaded file
};

export const downloadImageByDom = async (divId, filename = 'my-image-name') => {
  // eslint-disable-next-line promise/param-names
  return new Promise(function (myResolve, myReject) {
    domToImage.toJpeg(document.getElementById(divId), {
      quality: 0.95,
      bgcolor: 'white',
    })
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `${filename}.jpeg`;
        link.href = dataUrl;
        link.click();
        myResolve();
      }).catch(function (error) {
        myReject(new Error('Error'));
        console.error('oops, something went wrong!', error);
      });
  });
};

export const downloadCSV = async (data) => {
  const csv = data.result;
  const link = document.createElement('a');
  link.href = csv.file;
  link.setAttribute('download', csv.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const isStoreManager = (authUser) => {
  if (!authUser) return false;

  return Number(authUser.user_type) === Number(constant.user.type.USER_TYPE_STORE_MANAGER);
};

export const isCompanyManager = (authUser) => {
  if (!authUser) return false;

  return Number(authUser.user_type) === Number(constant.user.type.USER_TYPE_COMPANY_MANAGER);
};

export const isClientManager = (authUser) => {
  if (!authUser) return false;

  return Number(authUser.user_type) === Number(constant.user.type.USER_TYPE_CLIENT_MANAGER);
};

export const isGsAdmin = (authUser) => {
  if (!authUser) return false;

  return Number(authUser.user_type) === Number(constant.user.type.USER_TYPE_GS_ADMIN);
};

export const getPageToRedirectAfterDelete = (meta) => {
  const currentPage = Number(meta.current_page);
  const lastPage = Number(meta.last_page);
  const fromRecord = Number(meta.from);
  const toRecord = Number(meta.to);

  // first page
  if (currentPage === 1) return 1;

  // middle pages
  if (currentPage < lastPage) return currentPage;

  // last page
  if (!fromRecord && !toRecord) return 1;

  if (fromRecord === toRecord) return currentPage - 1;

  return currentPage;
};
/*
* Return width of single character
*/
export const mbCharwidth = (char) => {
  // source https://www.php.net/manual/en/function.mb-strwidth.php
  const fullSizes = [
    [0x1100, 0x115F],
    [0x11A3, 0x11A7],
    [0x11FA, 0x11FF],
    [0x2329, 0x232A],
    [0x2E80, 0x2E99],
    [0x2E9B, 0x2EF3],
    [0x2F00, 0x2FD5],
    [0x2FF0, 0x2FFB],
    [0x3000, 0x303E],
    [0x3041, 0x3096],
    [0x3099, 0x30FF],
    [0x3105, 0x312D],
    [0x3131, 0x318E],
    [0x3190, 0x31BA],
    [0x31C0, 0x31E3],
    [0x31F0, 0x321E],
    [0x3220, 0x3247],
    [0x3250, 0x32FE],
    [0x3300, 0x4DBF],
    [0x4E00, 0xA48C],
    [0xA490, 0xA4C6],
    [0xA960, 0xA97C],
    [0xAC00, 0xD7A3],
    [0xD7B0, 0xD7C6],
    [0xD7CB, 0xD7FB],
    [0xF900, 0xFAFF],
    [0xFE10, 0xFE19],
    [0xFE30, 0xFE52],
    [0xFE54, 0xFE66],
    [0xFE68, 0xFE6B],
    [0xFF01, 0xFF60],
    [0xFFE0, 0xFFE6],
    [0x1B000, 0x1B001],
    [0x1F200, 0x1F202],
    [0x1F210, 0x1F23A],
    [0x1F240, 0x1F248],
    [0x1F250, 0x1F251],
    [0x20000, 0x2FFFD],
    [0x30000, 0x3FFFD],
  ];
  const code = char.codePointAt(0);
  for (let index = 0; index < fullSizes.length; index++) {
    const item = fullSizes[index];
    if (item[0] <= code && code <= item[1]) {
      return 2;
    }
  }
  return 1;
};

export const downloadPDF = async (data) => {
  const pdf = data.result;
  const link = document.createElement('a');
  link.href = pdf.file;
  link.target = '_blank';
  link.setAttribute('download', pdf.name);
  document.body.appendChild(link);
  link.click();
  link.remove();
};

export const isDisplayErrorInLocalComponent = (errorCode) => {
  return [
    http.ERROR_CODE_VALIDATE,
    http.ERROR_CODE_BAD_REQUEST,
    http.ERROR_CODE_NOT_FOUND,
  ].indexOf(errorCode) !== -1;
};

export const isDisplayFormError = (errorCode) => {
  return [
    http.ERROR_CODE_VALIDATE,
  ].indexOf(errorCode) !== -1;
};
